:root {
    --themeColor: #855922;
    --darkBlue: #001B2E;
    --DarkText: linear-gradient(0deg, rgba(152, 111, 31, 1) 0%, rgba(255, 212, 76, 1) 100%);
    --lightText: linear-gradient(180deg, rgba(249, 247, 243, 1) 40%, rgba(255, 233, 188, 1) 100%);
    --buttonColor: linear-gradient(to top, #986f1f, #b2872a, #cca035, #e5b940, #ffd44c);
    --whiteColor: #FFFFFF;
    --bgColor: #F6F6F6;
    --bgColor2: #F9F7F3;
    --textGrey: #4D6075;
    --textPalceholder: #D7D3CB;
}